@import "./constants.scss";

.Main__root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .banners {
        //width: 75%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

       //margin-top: calc($bar_height + 48px);
        //margin-bottom: 48px;
        // gap: 48px;

        margin-top: $bar_height;

        .banner {
            width: 100%;
            aspect-ratio: 2;
            border: 1px solid black;
        }
    }
}

@media screen 
and (max-width: 1200px) {
    .Main__root {
        .banners {
            width: 85%;
            margin-top: calc($bar_height + 32px);
            gap: 32px;
        }
    }
}

@media screen 
and (max-width: 800px) {
    .Main__root {
        min-height: -webkit-fill-available;
        .banners {
            width: calc(100% - 16px);
            margin-top: calc($mobile_bar_height + 16px);
            gap: 24px;
        }
    }
}
