.Col__a {
    color: #000;
    text-decoration: none;
    width: 100%;
    aspect-ratio: 2;
}

.Col_banner__root {
    width: 100%;
    aspect-ratio: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    //justify-content: flex-end;
    //align-items: flex-end;

    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        //background-color: #f1f1f1;
        background-color: #000;
        
        .bg_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: filter .4s, opacity .4s;
            //filter: invert(1) hue-rotate(280deg) brightness(1.2);
        }
    }

    .name {
        font-size: 60px;
        font-family: "Molot";
        color: #000;
        color: #fff;
        z-index: 2;
        //text-decoration: underline;
        //text-underline-offset: 8px;

        //margin-top: 12vw;
        //margin: 0 2.5vw 2vw 0;
        //font-size: 80px;
        //color: #000;
        transition: color .4s;
        font-size: 24px;
        color: #fff;
    }

    .hovering:hover + .bg > .bg_img {
        //filter: brightness(0.5);
        opacity: 0.5;
        transition: filter .4s, opacity .4s;
    }

    /*.name:hover {
        color: #fff;
        transition: color .4s;
    }*/

    .line {
        position: relative;
        width: calc(100% - 64px);
        height: 4px;
        background-color: #fff;
        z-index: 2;
        margin-bottom: 2vw;
    }
}

@media screen 
and (max-width: 800px) {
    .Col_banner__root {
        aspect-ratio: 0.65;
        justify-content: flex-end;

        .name {
            //margin-top: 36vw;
            //margin-bottom: 6vw;
        }

        .line {
            margin-bottom: 4vw;
            width: calc(100% - 32px);
        }
    }
}