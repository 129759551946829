.GamePage__root {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-family: monospace;
        font-size: 14px;
        color: #525252;
        text-align: center;
        max-width: 80%;
    }
}
