@import "../styles/constants.scss";

.Footer__root {
    width: 100%;
    height: $footer_height;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #f1f1f1;

    .logo {
        font-family: 'Molot';
        text-decoration: none;
        color: #000;
        font-size: 20px;
    }

    .horizontal {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin: 8px 0 0 0;

        a {
            color: #525252;
            //font-family: "Molot";
            font-family: monospace;
            font-weight: 600;
            font-size: 16px;
            text-decoration: none;
        }
    
        a:hover {
            text-decoration: underline;
        }

        .link {
            color: #525252;
            //font-family: "Molot";
            font-family: monospace;
            font-weight: 600;
            font-size: 16px;
            text-decoration: none;
        }
    
        .link:hover {
            text-decoration: underline;
        }

        @media screen and (max-width: 800px) {
            .link {
                font-size: 12px;
                text-transform: lowercase;
            }
        }
    }

    .credentials {
        color: #525252;
        //font-family: "Molot";
        font-family: monospace;
        font-weight: 600;
        font-size: 12px;
        text-decoration: none;
        margin: 12px 0 0 0;

        span {
            text-decoration: none;
        }
    }

    .credentials:hover > span {
        text-decoration: underline;
    }
}