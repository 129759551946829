@import "./constants.scss";

.Cart__root {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .wrapper {
        width: calc(100% - 128px);
        margin: $bar_height 0 0 0;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: max-content;
        grid-auto-rows: calc(100vh - $bar_height);
        gap: 64px;

        .catalog_button {
            width: 80%;
            max-width: 300px;
            height: 50px;
            margin: 32px 0 0 0;
            background-color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;

            p {
                //font-family: 'Molot';
                color: #fff;
                //font-size: 30px;
                font-size: 18px;
                font-family: monospace;
            }
        }

        .left {
            width: 100%;
            height: max-content;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            overflow: hidden;

            //border: 1px solid red;

            .title {
                font-family: monospace;
                font-size: 30px;
                color: #000;
                margin: 32px 0 0 0;
            }

            .cart_items_wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 12px;
                margin: 32px 0 0 0;
            }

            .total {
                margin: 32px 0 32px 0;
            }
        }

        .right {
            width: 100%;
            height: calc(100vh - $bar_height);

            overflow-x: hidden;
            overflow-y: auto;

            .scroll_wrap {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: max-content;

                .title {
                    font-family: monospace;
                    font-size: 30px;
                    color: #000;
                    margin: 32px 0 0 0;
                }
    
                .section_title {
                    font-family: monospace;
                    font-size: 20px;
                    color: #252525;
                    margin: 24px 0 18px 0;
                }
    
                .inputs_wrapper {
                    width: calc(100% - 64px);

                    .pickup_info {
                        font-family: monospace;
                        font-size: 16px;
                        color: #525252;
                        margin: 24px 0 0 0;

                        a {
                            color: #000;
                            text-decoration: none;
                        }

                        a:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .pay_button {
                    width: calc(100% - 49px);
                    //max-width: 300px;
                    height: 50px;
                    margin: 0 0 32px;
                    background-color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    text-decoration: none;
                    margin-top: 24px;
            
                    p {
                        //font-family: 'Molot';
                        color: #fff;
                        //font-size: 30px;
                        font-size: 18px;
                        font-family: monospace;
                    }
                }
            }
        }
    }
}

@media screen 
and (max-width: 800px) {
    .Cart__root {
        .wrapper {
            width: calc(100% - 64px);
            display: flex;
            flex-direction: column;
            gap: 0;  

            .left {
                .title {
                    margin: 16px 0 0 0;
                }

                .total {
                    margin: 48px 0 0 0;
                }
            }

            .right {
                height: max-content;
                overflow: hidden;

                .scroll_wrap {
                    .inputs_wrapper {
                        width: calc(100% - 22px);
                    }

                    .pay_button {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.CartItem__root {
    width: 100%;
    height: 160px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    gap: 24px;

    img {
        width: 160px;
        height: 160px;
        min-width: 160px;
        object-fit: cover;
        overflow: hidden;
        filter: brightness(1.05);
    }

    .desc_container {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .name {
            color: #000;
            font-family: monospace;
            font-weight: 600;
            font-size: 20px;
        }

        .size {
            color: #525252;
            font-family: monospace;
            font-size: 20px;
            font-weight: 400;
            margin: 12px 0;
        }

        .price {
            color: #525252;
            font-family: monospace;
            font-size: 20px;
            font-weight: 400;
        }
    }

    .actions {
        height: 24px;
        width: 24px;
        min-width: 24px;
        //border: 1px solid #252525;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0 16px 0 0;
        cursor: pointer;
    }
}

@media screen and (max-width: 800px) {
    .CartItem__root {
        height: 120px;
        gap: 20px;

        img {
            width: 120px;
            height: 120px;
            min-width: 120px;
        }

        .desc_container {
            .name {
                font-size: 16px;
            }

            .size {
                font-size: 16px;
            }

            .price {
                font-size: 16px;
            }
        }

        .actions {
            margin: 0;
        }
    }
}