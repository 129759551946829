.Selector__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: max-content;

    gap: 8px;

    .sl_title {
        color: #252525;
        font-size: 16px;
        font-family: monospace;

        margin: 0 0 0 8px;
    }

    .item {
        width: max-content;
        height: max-content;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        gap: 12px;

        .checkbox {
            width: 24px;
            height: 24px;
            border: 1px solid black;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .item_title {
            color: #000;
            font-size: 16px;
            font-family: monospace;
        }
    }
}
