@import "../styles/constants.scss";

.Catalog__root {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: max-content;
    gap: 16px;

    .view_selector, .car_link {
        width: 40px;
        height: 40px;
        position: fixed;
        left: 32px;
        bottom: 32px;
        z-index: 10;
        border: 2px solid black;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            height: 32px;
            width: 32px;
            object-fit: contain;
        }
    }

    .car_link {
        left: 88px;
    }
}

@media screen
and (max-width: 1200px) {
    .Catalog__root {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen
and (max-width: 800px) {
    .Catalog__root {
        grid-template-columns: 1fr 1fr;
        gap: 0;

        .view_selector {
            left: 24px;
            bottom: 24px;
        }

        .car_link {
            left: 24px;
            bottom: 80px;
        }
    }
}

.Product__root {
    width: 100%;
    height: max-content;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: #000;

    align-items: center;

    .image_container {
        width: 100%;
        aspect-ratio: 0.8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: #fff;//#f1f1f1;

        .img1 {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            display: block;
            position: absolute;
            filter: brightness(1.05); // 1.05
            transform: rotate(0deg);
        }

        .img2 {
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            display: none;
            position: absolute;
            filter: brightness(1.05); // 1.05
        }
    }

    .name {
        margin: 8px 0 8px 0;
        font-family: monospace;
        font-size: 20px;
        font-weight: 600;
        width: 100%;
        text-align: left;

        text-align: center;
        font-size: 14px;
    }

    .price {
        margin: 0 0 8px 0;
        font-family: monospace;
        font-size: 20px;
        color: #525252;
        font-size: 14px;
    }

    .out_of_stock {
        margin: 0 0 8px 0;
        font-family: monospace;
        font-size: 20px;
        color: #525252;
        font-size: 14px;
    }

    .sizes {
        margin: 0 0 8px 0;
        font-family: monospace;
        color: #525252;
        display: none;
        font-size: 20px;
        font-size: 14px;
    }

    @media screen 
    and (max-width: 800px) {
        .name { 
            margin: 0 0 4px 8px;
            font-size: 16px; 
            font-size: 14px;
        }

        .price {
            margin: 0 0 0 8px;
            font-size: 14px;
        }

        .out_of_stock {
            margin: 0 0 0 8px;
            font-size: 14px;
        }

        .sizes {
            margin: 0 0 0 8px;
            font-size: 14px;
            text-align: center;
        }
    }
}

.Product__root:hover {
    .image_container {
        .img1 {
            display: none;
        }

        .img2 {
            display: block;
        }
    }

    .sizes {
        display: block;
    }

    .price {
        display: none;
    }
}