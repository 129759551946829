@import "./constants.scss";

.PP__root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .overlay_gallery {
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 10;
        left: 0;
        top: 0;
        background-color: #fff;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .sel1, .sel2 {
            width: 50px;
            height: 100px;
            margin: 16px;
            cursor: pointer;
            z-index: 11;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .image {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
            filter: brightness(1.05);
        }

        .close_button {
            position: absolute;
            right: 32px;
            top: 32px;
            width: 32px;
            height: 32px;
            cursor: pointer;
            z-index: 11;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .grid {
        width: 100%;
        height: max-content;
        margin-top: $bar_height;

        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-auto-rows: max-content;
        gap: 16px;

        .gallery {
            width: 100%;
            max-width: 100%;
            aspect-ratio: 1;

            display: grid;
            grid-template-areas: "img1 img3"
                                 "img2 img3";
            gap: 16px;

            .img-wrapper {
                width: 100%;
                max-width: calc((100vw - 32px)/3);
                min-width: calc((100vw - 32px)/3);
                height: 100%;
                max-width: 100%;
                background-color: #fff;// #f6f6f6;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                position: relative;
                cursor: pointer;

                .img {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    //overflow: hidden;
                    //aspect-ratio: 1;
                    filter: brightness(1.05);
                    //transform: scale(0.9);
                }

                .lightup {
                    filter: brightness(1.05); // 1.7
                    transform: scale(1.1);
                }
                
                .tall {
                    aspect-ratio: 0.5;
                    object-fit: cover;
                    filter: brightness(1.1);
                }
            }

            .img1 {
                grid-area: img1;
                aspect-ratio: 1;
            }

            .img2 {
                grid-area: img2;
                aspect-ratio: 1;
            }

            .img3 {
                grid-area: img3;
                aspect-ratio: 0.5;
            }
        }

        .actions {
            width: calc(100% - 64px);
            height: max-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 32px;
            position: sticky;
            top: 36px;

            .name {
                font-family: monospace;
                font-size: 30px;
                font-weight: 600;
                margin-top: 40px;
            }

            .desc {
                color: #525252;
                font-size: 20px;
                font-family: monospace;
            }

            .size_selector {
                width: 100%;
                display: flex;
                //flex-direction: column;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 8px;
                margin: 16px 0 16px 0;

                .size_item {
                    width: calc(100% - 4px);
                    height: 46px;
                    border: 2px solid black;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    transition: border-color .1s;

                    .size {
                        color: #525252;
                        font-size: 20px;
                        font-family: monospace;
                        margin-left: 16px;
                    }

                    .lastone {
                        font-size: 16px;
                        color: #525252;
                        font-family: monospace;
                    }

                    .price {
                        color: #000;
                        font-size: 20px;
                        font-family: monospace;
                        margin-right: 16px;
                    }
                }

                .size_item_small {
                    width: 46px;
                    height: 46px;
                    border: 2px solid black;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    transition: border-color .1s;

                    .size {
                        color: #525252;
                        font-size: 20px;
                        font-family: monospace;
                        //margin-left: 16px;
                    }
                }
            }

            .price_single {
                color: #000;
                font-size: 24px;
                font-family: monospace;
                margin: 12px 0 8px 0;
            }

            .cart_button {
                width: 100%;
                height: 50px;
                background-color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .cart_title {
                    //font-family: 'Molot';
                    color: #fff;
                    //font-size: 30px;
                    font-size: 18px;
                    font-family: monospace;
                }
            }

            .cart_button_disabled {
                width: 100%;
                height: 50px;
                background-color: #f1f1f1;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;

                .cart_title {
                    //font-family: 'Molot';
                    color: #525252;
                    //font-size: 30px;
                    font-size: 18px;
                    font-family: monospace;
                }
            }

            .cart_active {
                background-color: #fff;
                border: 2px solid black;
                height: 46px;

                .cart_title {
                    color: #000;
                }
            }

            .composition_title {
                font-size: 20px;
                color: #000;
                font-family: monospace;
                margin: 24px 0 6px 0;
                font-weight: 600;
            }

            .composition_container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 16px;
                margin: 0 0 4px 0;

                .alias {
                    color: #525252;
                    font-family: monospace;
                    font-size: 16px;
                }

                .value {
                    color: #000;
                    font-family: monospace;
                    font-size: 16px;
                }
            }

            .desc_container {
                display: grid;
                margin: 0 0 0 0;

                .desc {
                    color: #525252;
                    font-family: monospace;
                    font-size: 16px;
                }
            }
        }
    }

    @media screen 
    and (max-width: 800px) {
        .grid {
            grid-template-columns: 1fr;

            .gallery {
                width: calc(100% - 16px);
                margin-left: 16px;
                gap: 0;
            }

            .actions {
                top: 0;
                margin-top: 0;

                .name {
                    margin-top: 0;
                    font-size: 24px;
                }
            }
        }
    }
}