@import "./constants.scss";

.Contacts__root {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

    .wrapper {
        width: 75%;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: $bar_height;
        margin-bottom: 64px;

        .title {
            //font-family: "Molot";
            font-family: monospace;
            font-size: 30px;
            color: #000;
            margin: 32px 0 16px 0;
        }

        .content {
            width: 100%;
            text-align: left;
            font-size: 16px;
            color: #525252;
            font-family: monospace;

            a {
                color: #000;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}