@import "./constants.scss";

.Coll__root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .catalog_wrapper {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: $bar_height;
        margin-bottom: 32px;

        .title {
            font-family: 'Molot';
            font-size: 80px;
            color: #000;
            margin: 32px 0 0 0;
        }

        .desc {
            font-family: monospace;
            font-size: 20px;
            color: #525252;
            margin: 16px 0 32px 0;
            width: 50%;
            text-align: center;
        }
    }

    .show_all_button {
        width: 80%;
        max-width: 300px;
        height: 50px;
        margin: 0 0 32px;
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        p {
            //font-family: 'Molot';
            color: #fff;
            //font-size: 30px;
            font-size: 18px;
            font-family: monospace;
        }
    }
}

@media screen
and (max-width: 1200px) {
    .Coll__root {
        .catalog_wrapper {
            width: 85%;
        }
    }
}

@media screen
and (max-width: 800px) {
    .Coll__root {
        .catalog_wrapper {
            width: calc(100% - 16px);
        }
    }
}