@import "./constants.scss";

.Gallery__root {
    width: 100%;
    height: max-content;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: $bar_height;

    .single {
        width: 100%;
        height: max-content;
        aspect-ratio: 2;
        overflow: hidden;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        img {
            width: 100%;
            aspect-ratio: 0.5;
            object-fit: contain;
        }
    }

    .double {
        width: 100%;
        height: max-content;
        aspect-ratio: 2;
        overflow: hidden;
        position: relative;

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0;
        grid-auto-rows: max-content;

        img {
            width: 100%;
            height: 100%;
            aspect-ratio: 1;
            object-fit: cover;
        }

        .blur {
            filter: blur(3px) hue-rotate(20deg) brightness(1.3);
        }

        @media screen 
        and (max-width: 800px) {
            .blur {
                filter: blur(1px) hue-rotate(20deg) brightness(1.3);
            }
        }
    }

    .single_tall {
        width: 100%;
        height: max-content;
        overflow: hidden;
        position: relative;
        // margin: 10vw 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        img {
            width: 100%;
            object-fit: contain;
        }
    }
}
