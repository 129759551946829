@import "./constants.scss";

.CatalogPage__root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .catalog_wrapper {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: $bar_height;
        margin-bottom: 48px;

        // gap: 64px;

        .title {
            font-family: 'Molot';
            font-size: 80px;
            color: #000;
            margin: 32px 0 0 0;
        }

        .desc {
            font-family: monospace;
            font-size: 20px;
            color: #525252;
            margin: 16px 0 32px 0;
            width: 50%;
            text-align: center;
        }

        .drop_title {
            font-family: 'Molot';
            font-size: 24px;
            color: #161616;
            margin: 64px 0 0 0;
            align-self: flex-start;
            // text-decoration: underline;
        }
    }

    .view_selector, .car_link {
        width: 40px;
        height: 40px;
        position: fixed;
        left: 32px;
        bottom: 32px;
        z-index: 10;
        border: 2px solid black;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            height: 32px;
            width: 32px;
            object-fit: contain;
        }
    }

    .car_link {
        left: 88px;
    }
}

@media screen 
and (max-width: 1200px) {
    .CatalogPage__root {
        .catalog_wrapper {
            width: 85%;

            .drop_title {
                margin-top: 48px;
            }
        }
    }
}

@media screen 
and (max-width: 800px) {
    .CatalogPage__root {
        .catalog_wrapper {
            width: calc(100% - 16px);

            .drop_title {
                margin-top: 48px;
                align-self: center;
                // margin-left: 16px;
            }
        }
    }
}
