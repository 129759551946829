@import "../styles/constants.scss";

.Nav__root {
    width: 100%;
    height: $bar_height;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .logo {
        font-family: 'Molot';
        font-size: 40px;
        color: #000;
        cursor: pointer;
        text-decoration: none;
    }

    .navigation {
        position: absolute;
        left: 32px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 24px;

        .item {
            font-family: monospace;
            color: #000;
            text-decoration: none;
            font-weight: 600;
            font-size: 16px;
        }

        .item:hover {
            color: #525252;
        }
    }

    .profile {
        position: absolute;
        right: 32px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 24px;

        .item {
            font-family: monospace;
            color: #000;
            text-decoration: none;
            font-weight: 600;
            font-size: 16px;
        }

        .item:hover {
            color: #525252;
        }
    }

    .menu {
        display: none;
    }

    .cart_link {
        display: none;
    }
}

@media screen 
and (max-width: 1200px) {
    .Nav__root {
        .navigation {
            display: none;
        }

        .profile {
            display: none;
        }

        .menu {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 32px;
            height: 20px;

            position: absolute;
            left: 24px;

            cursor: pointer;

            div {
                width: 32px;
                height: 5px;
                background-color: #000;
            }
        }

        .cart_link {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 24px;
            height: 24px;

            position: absolute;
            right: 24px;

            cursor: pointer;

            img {
                width: 24px;
                height: 24px;
                object-fit: contain;
            }
        }
    }
}

.Menu__root {
    width: 100%;
    height: calc(100vh - $bar_height);
    position: fixed;
    left: 0;
    top: $bar_height;
    background-color: #fff;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .item {
        margin-left: 24px;
        margin-top: 16px;
        font-size: 26px;
        font-weight: 600;
        font-family: monospace;
        color: #000;
        text-decoration: none;
    }

    .with_spacing {
        margin-top: 64px;
    }
}

@media screen and (max-width: 800px) {
    .Nav__root {
        height: $mobile_bar_height;
    }
}
