.TextInput__container {
    width: 100%;
    height: 92px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    //overflow: hidden;

    .ti_title {
        color: #252525;
        font-size: 16px;
        font-family: monospace;

        margin: 0 0 4px 8px;
    }

    input {
        height: 40px;
        border: 1px solid #000;
        padding-left: 12px;
        font-size: 16px;
        width: 100%;
        outline: none;
        font-family: monospace;
    }
}