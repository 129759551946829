@import "./constants.scss";

.Checkout__root {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .wrapper {
        width: calc(100% - 128px);
        margin: $bar_height 0 0 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .title {
            font-family: monospace;
            font-size: 30px;
            color: #000;
            margin: 32px 0 0 0;
            text-align: center;
        }

        .total {
            font-family: monospace;
            font-size: 16px;
            color: #000;
            margin: 16px 0 0 0;
            text-align: center;
        } 

        #checkout {
            width: calc(100% - 49px);
            max-width: 300px;
            height: 50px;
            margin: 0 0 32px;
            background-color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            margin-top: 20px;
    
            p {
                //font-family: 'Molot';
                color: #fff;
                //font-size: 30px;
                font-size: 18px;
                font-family: monospace;
            }
        }
    }
}
