@import "./constants.scss";

.Track__root {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .wrapper {
        width: calc(100% - 128px);
        margin: $bar_height 0 0 0;

        .title {
            font-family: monospace;
            font-size: 30px;
            color: #000;
            margin: 32px 0 12px 0;
        }

        .desc {
            font-family: monospace;
            font-size: 16px;
            color: #000;
            margin: 0 0 24px 0;
        }

        .table {
            width: 100%;
            max-width: 700px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: max-content;
            gap: 16px 0;

            .g_title {
                font-family: monospace;
                font-size: 20px;
                color: #525252;
            }

            .g_value {
                font-family: monospace;
                font-size: 20px;
                color: #000;
            }

            .pay_button {
                width: calc(100% - 49px);
                max-width: 300px;
                height: 50px;
                margin: 0 0 32px;
                background-color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                text-decoration: none;
                margin-top: 20px;
        
                p {
                    //font-family: 'Molot';
                    color: #fff;
                    //font-size: 30px;
                    font-size: 18px;
                    font-family: monospace;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .Track__root {
        .wrapper {
            width: calc(100% - 64px);
            
            .table {
                display: flex;
                flex-direction: column;
                gap: 0;
                margin-bottom: 64px;

                .g_title {
                    margin: 20px 0 6px 0;
                }
            }
        }
    }
}