.Security__root {
    width: 100%;
    height: max-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .igor_c {
        height: 50vh;
        width: 14vh;
        // overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        img {
            height: 50vh;
            width: 40vh;
            transform: scaleX(2);
            transition: transform .2s;
        }
    }

    p {
        top: 10vh;
        position: absolute;
        z-index: 100;
        color: #f0f;
        font-size: 200px;
        font-family: 'Molot';
        text-align: center;
        width: 100%;
        text-shadow: red 5px 5px;
    }
}
